<template lang="html">
  <div class="">

    <div class="informationPerfect">
      <div class="box">
        <div class="title">
          资料完善
        </div>
        <div class="">
          <navlid :list="navList"  style="min-width: auto"/>
          <div class="panel">
            <div class="left">
              <input type="file" placeholder="" ref="headImg" @change="inputFile" accept="image/*" style="display:none"
                     id="file"/>
              <label for="file">
                <div class="header">
                  <img :src="params.headerImg ? params.headerImg : require('../assets/img/profile.png')" alt="">
                  <span>修改头像</span>
                </div>
              </label>
              </file>
            </div>
            <div class="right">
              <div class="list">
                <span class="name">登录账号：</span>
                <span class="listText">{{ userInfo && userInfo.user.userPhone }}</span>
              </div>
              <div class="list">
                <span class="name">账号名称：</span>
                <span class="listText">{{ userInfo && userInfo.user.nickName }}</span>
              </div>
              <div class="list">
                <span class="name">注册时间：</span>
                <span class="listText">{{ userInfo && userInfo.user.createAt }}</span>
              </div>
            </div>
          </div>
          <div class="tips" v-if="params.reviewerOpinion && params.userRecycle == 20">
            温馨提示:{{ params.reviewerOpinion }}
          </div>
          <div class="content" v-if="isShow == 1">
            <div class="list">
              <span class="name">企业名称</span>
              <div class="accout">
                <input type="text" v-model="params.userName" placeholder="输入企业名称"/>
              </div>
            </div>
            <div class="list">
              <span class="name">姓名</span>
              <div class="accout">
                <input type="text" v-model="params.nickName" placeholder="联系人姓名"/>
              </div>
            </div>
            <div class="list">
              <span class="name">联系电话</span>
              <div class="accout">
                <input type="text" disabled v-model="params.userPhone" placeholder="联系电话"/>
              </div>
            </div>
            <div class="list">
              <span class="name">统一社会信用代码</span>
              <div class="accout">
                <input type="text" v-model="params.creditCode" placeholder="统一社会信用代码"/>
              </div>
            </div>
            <div class="list">
              <span class="name">上传营业执照</span>
              <div class="fileBox">
                <input type="file" ref="businessLicense" @change="businessLicense">
                <img :src="params.cloudBusiness" class="imgBox" v-if="params.cloudBusiness" alt="">
                <div v-else>
                  <img src="../assets/img/fileIcon.png" alt="">
                  <p>点击上传营业执照</p>
                </div>
              </div>
            </div>
            <div class="list">
              <span class="name">上传经营许可证</span>
              <div class="fileBox">
                <input type="file" ref="otherCardChange" @change="otherCardChange">
                <img :src="params.otherCard" class="imgBox" v-if="params.otherCard" alt="">
                <div v-else>
                  <img src="../assets/img/fileIcon.png" alt="">
                  <p>点击上传经营许可证</p>
                </div>
              </div>
            </div>
            <div class="list">
              <span class="name">上传LOGO</span>
              <div class="logoBox">
                <input type="file" ref="logoChange" @change="logoChange">
                <img :src="params.userLogo" class="imgBox" v-if="params.userLogo" alt="">
                <div v-else>
                  <img src="../assets/img/fileIcon.png" alt="">
                  <p>点击上传经营许可证</p>
                </div>
              </div>
            </div>
            <div class="list">
              <span class="name">购买类型</span>
              <div class="buyType">
                <div @click="params.buyerType =10">
                  <span :class="['icon', params.buyerType==10 ? 'yes' : 'no']"></span>
                  <span>云系统</span>
                </div>
                <div @click="params.buyerType =20">
                  <span :class="['icon', params.buyerType==20 ? 'yes' : 'no']"></span>
                  <span>私有化系统部署</span>
                </div>
              </div>
            </div>
            <div class="list">
              <span class="name">收款银行类型</span>
              <div class="buyType">
                <div @click="params.bankType = 10">
                  <span :class="['icon', params.bankType==10 ? 'yes' : 'no']"></span>
                  <span>特约</span>
                </div>
                <div @click="params.bankType =20">
                  <span :class="['icon', params.bankType==20 ? 'yes' : 'no']"></span>
                  <span>自签</span>
                </div>
              </div>
            </div>
            <div class="btn" @click="affirm">
              <button type="button">提交</button>
            </div>
          </div>
          <div class="content" v-if="isShow == 2">
            <div class="list">
              <span class="name">姓名</span>
              <div class="accout">
                <input type="text" v-model="params.nickName" placeholder="姓名"/>
              </div>
            </div>
            <div class="list">
              <span class="name">联系电话</span>
              <div class="accout">
                <input type="text" disabled v-model="params.userPhone" placeholder="联系电话"/>
              </div>
            </div>
            <div class="list">
              <span class="name">联系地址</span>
              <div class="accout">
                <input type="text" v-model="params.userAddress" placeholder="联系地址"/>
              </div>
            </div>
            <div class="list">
              <span class="name">身份证号码</span>
              <div class="accout">
                <input type="text" v-model="params.userIdCode" placeholder="身份证号码"/>
              </div>
            </div>
            <div class="list">
              <span class="name">上传身份证正面</span>
              <div class="fileBox">
                <input type="file" ref="idCardJust" @change="idCardJustChange">
                <img :src="params.idCardJust" class="imgBox" v-if="params.idCardJust" alt="">
                <div v-else>
                  <img src="../assets/img/fileIcon.png" alt="">
                  <p>点击上传身份证正面</p>
                </div>
              </div>
            </div>
            <div class="list">
              <span class="name">上传身份证反面</span>
              <div class="fileBox">
                <input type="file" ref="idCardBack" @change="idCardBackChange">
                <img :src="params.idCardBack" class="imgBox" v-if="params.idCardBack" alt="">
                <div v-else>
                  <img src="../assets/img/fileIcon.png" alt="">
                  <p>点击上传身份证反面</p>
                </div>
              </div>
            </div>
            <div class="list">
              <span class="name">上传LOGO</span>
              <div class="logoBox">
                <input type="file" ref="logoChange" @change="logoChange">
                <img :src="params.userLogo" class="imgBox" v-if="params.userLogo" alt="">
                <div v-else>
                  <img src="../assets/img/fileIcon.png" alt="">
                  <p>点击上传经营许可证</p>
                </div>
              </div>
            </div>
            <div class="list">
              <span class="name">购买类型</span>
              <div class="buyType">
                <div @click="params.buyerType =10">
                  <span :class="['icon', params.buyerType==10 ? 'yes' : 'no']"></span>
                  <span>云系统</span>
                </div>
                <div @click="params.buyerType =20">
                  <span :class="['icon', params.buyerType==20 ? 'yes' : 'no']"></span>
                  <span>私有化系统部署</span>
                </div>
              </div>
            </div>
            <div class="list">
              <span class="name">收款银行类型</span>
              <div class="buyType">
                <div @click="params.bankType =10">
                  <span :class="['icon', params.bankType==10 ? 'yes' : 'no']"></span>
                  <span>特约</span>
                </div>
                <div @click="params.bankType =20">
                  <span :class="['icon', params.bankType==20 ? 'yes' : 'no']"></span>
                  <span>自签</span>
                </div>
              </div>
            </div>
            <div class="btn" @click="affirm">
              <button type="button">提交</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <myBase style="margin-top:50px"></myBase>
  </div>

</template>

<script>
import myBase from "@/components/myBase.vue";

export default {
  name: "modifyInformation",
  components: {
    myBase,
  },
  data() {
    return {
      isShow: 1,
      userInfo: window.localStorage.getItem("userInfo") ?
          JSON.parse(window.localStorage.getItem("userInfo")) :
          null,
      navList: [{
        title: '个人中心',
        url: '/personalCenter',
      }, {
        title: '账号中心',
        url: '',
      }],
      params: {
        userName: "",
        nickName: "",
        userPhone: "",
        creditCode: "",
        cloudBusiness: "",
        otherCard: "",
        userLogo: "",
        userAddress: "",
        userIdCode: "",
        idCardJust: "",
        idCardBack: "",
        buyerType: 10,
        bankType: 10,
        reviewerOpinion: "",
        userRecycle: "",
        headerImg: '',
      },
    };
  },
  created() {
    this.getUserInfo();
  },
  methods: {
    getUserInfo() {
      this.$request.HttpGet("/api/userInfo").then((res) => {
        if (res.data.userType == 10) {
          this.isShow = 2;
        } else {
          this.isShow = 1;
        }
        this.params.userName = res.data.userName;
        this.params.nickName = res.data.nickName;
        this.params.userPhone = res.data.userPhone;
        this.params.creditCode = res.data.creditCode;
        this.params.cloudBusiness = res.data.cloudBusiness;
        this.params.otherCard = res.data.otherCard;
        this.params.userLogo = res.data.userLogo;
        this.params.userAddress = res.data.userAddress;
        this.params.userIdCode = res.data.userIdCode;
        this.params.idCardJust = res.data.idCardJust;
        this.params.idCardBack = res.data.idCardBack;
        this.params.reviewerOpinion = res.data.reviewerOpinion;
        this.params.userRecycle = res.data.userRecycle;
        this.params.headerImg = res.data.headerImg
      });
    },
    affirm() {
      if (!this.params.nickName) {
        this.$toast("请输入姓名");
        return;
      }
      if (!this.params.userPhone) {
        this.$toast("请输入联系电话");
        return;
      }
      if (this.isShow == 1) {
        if (!this.params.userName) {
          this.$toast("请输入企业名称");
          return;
        }
        if (!this.params.creditCode) {
          this.$toast("请输入统一社会信用代码");
          return;
        }
        if (!this.params.cloudBusiness) {
          this.$toast("请上传营业执照");
          return;
        }
        if (!this.params.otherCard) {
          this.$toast("请上传经营许可证");
          return;
        }
      } else {
        if (!this.params.userAddress) {
          this.$toast("请输入联系地址");
          return;
        }
        if (!this.params.userIdCode) {
          this.$toast("请输入身份证号码");
          return;
        }
        if (!this.params.idCardJust) {
          this.$toast("请上传身份正面");
          return;
        }
        if (!this.params.idCardBack) {
          this.$toast("请上传身份反面");
          return;
        }
      }
      if (!this.params.userLogo) {
        this.$toast("请上传LOGO");
        return;
      }
      delete this.params.headerImg;
      delete this.params.userRecycle;
      delete this.params.userReviewer;
      delete this.params.reviewerOpinion
      this.$request.HttpPost("/api/userPerfect", this.params).then((res) => {
        // this.$router.push({ path: '/personalCenter' });
        this.$toast("修改成功");
      });
    },
    businessLicense(event) {
      let file = this.$refs.businessLicense;
      if (file && file.files.length > 0) {
        let formDate = new FormData();
        formDate.append("file", file.files[0]);
        this.$request.HttpPost("/api/upload", formDate).then((res) => {
          this.params.cloudBusiness =
              process.env.VUE_APP_URL_IMG + res.data.file.url;
        });
      }
    },
    otherCardChange(event) {
      let file = this.$refs.otherCardChange;
      if (file && file.files.length > 0) {
        let formDate = new FormData();
        formDate.append("file", file.files[0]);
        this.$request.HttpPost("/api/upload", formDate).then((res) => {
          this.params.otherCard =
              process.env.VUE_APP_URL_IMG + res.data.file.url;
        });
      }
    },
    logoChange(event) {
      let file = this.$refs.logoChange;
      if (file && file.files.length > 0) {
        let formDate = new FormData();
        formDate.append("file", file.files[0]);
        this.$request.HttpPost("/api/upload", formDate).then((res) => {
          this.params.userLogo =
              process.env.VUE_APP_URL_IMG + res.data.file.url;
        });
      }
    },
    idCardJustChange(event) {
      let file = this.$refs.idCardJust;
      if (file && file.files.length > 0) {
        let formDate = new FormData();
        formDate.append("file", file.files[0]);
        this.$request.HttpPost("/api/upload", formDate).then((res) => {
          this.params.idCardJust =
              process.env.VUE_APP_URL_IMG + res.data.file.url;
        });
      }
    },
    idCardBackChange(event) {
      let file = this.$refs.idCardBack;
      if (file && file.files.length > 0) {
        let formDate = new FormData();
        formDate.append("file", file.files[0]);
        this.$request.HttpPost("/api/upload", formDate).then((res) => {
          this.params.idCardBack =
              process.env.VUE_APP_URL_IMG + res.data.file.url;
        });
      }
    },
    inputFile(file) {
      let target = this.$refs.headImg;
      if (target.files.length > 0) {
        let obj = new FormData();
        console.log(target.files)
        obj.append('file', target.files[0])
        this.$request.HttpPost('/api/upload', obj)
            .then(res => {
              if (res.code == 0) {
                this.params.headerImg = process.env.VUE_APP_URL_IMG + res.data.file.url;
                console.log(this.params.headerImg)
                this.$request.HttpPost("/api/userPerfect", {
                  headerImg: this.params.headerImg,
                }).then((res) => {
                  // this.$router.push({ path: '/personalCenter' });
                  this.$toast("修改成功");
                });
                // this.params.headerImg =
              }
            })
      }

    },
  },
};
</script>

<style lang="scss" scoped>
.informationPerfect {
   min-height: 100vh;
  // padding-bottom: 150px;
  // margin-bottom: 50px;
  background-color: #FAFAFA;
  .box {
    display: flex;
    justify-content: center;
    min-height: calc(100vh - 210px);
    min-width: 1200px;
    max-width: 1400px;
    margin: 20px auto;

    .title {
      font-size: 14px;
      color: #333333;
      position: relative;
      height: 20px;
      margin-right: 20px;
      margin-top: 44px;

      &:before {
        content: " ";
        position: absolute;
        width: 3px;
        height: 14px;
        background: #3d7eff;
        top: 50%;
        transform: translateY(-50%);
        left: -8px;
      }
    }

    .panel {
      width: 800px;
      height: 100px;
      background: #ffffff;
      box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.04);
      margin-bottom: 15px;
      display: flex;
      align-items: center;

      .left {
        margin-left: 50px;
        margin-right: 25px;

        .header {
          width: 72px;
          height: 72px;
          border-radius: 50%;
          position: relative;
          overflow: hidden;
          cursor: pointer;

          &:hover {
            span {
              display: block;
            }
          }

          img {
            width: 100%;
            height: 100%;
          }

          span {
            display: block;
            position: absolute;
            left: 0;
            top: 0;
            text-align: center;
            line-height: 95px;
            width: 100%;
            height: 100%;
            background: rgba($color: #000000, $alpha: .4);
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #FFFFFF;
            display: none;
          }
        }
      }

      .right {
        .list {
          font-size: 12px;
          color: #333333;
          margin-top: 10px;

          .name {
            color: #666666;
            margin-right: 10px;
          }
        }
      }
    }

    .tips {
      width: 288px;
      height: 30px;
      background: url("../assets/img/tips.png") no-repeat;
      background-size: 288px 30px;
      margin-left: 219px;
      font-size: 12px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #cb191a;
      // text-align: center;
      padding-left: 18px;
      line-height: 22px;
      margin-bottom: 10px;
    }

    .content {
      width: 800px;
      min-height: 670px;
      background: #ffffff;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.06);
      padding-top: 30px;
      box-sizing: border-box;
      padding-bottom: 40px;

      .list {
        display: flex;
        margin-bottom: 10px;

        span {
          display: block;
          width: 200px;
          margin-right: 20px;
          text-align: right;
          font-size: 12px;
          color: #666666;
          height: 42px;
          padding-top: 15px;
          box-sizing: border-box;
        }

        .accout {
          width: 360px;
          height: 42px;
          line-height: 42px;
          box-sizing: border-box;
          background: #ffffff;
          border: 1px solid #c8c8c8;
          padding-left: 20px;
          display: flex;
          align-items: center;
        }

        .fileBox {
          width: 360px;
          height: 152px;
          background: #ffffff;
          border: 1px dashed #c8c8c8;
          text-align: center;
          position: relative;

          input {
            width: 360px;
            height: 152px;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 10;
            opacity: 0;
            cursor: pointer;
          }

          .imgBox {
            width: 360px;
            height: 152px;
            padding: 0;
            object-fit: cover;
          }

          img {
            display: block;
            margin: 0 auto;
            width: 48px;
            height: 48px;
            padding-top: 40px;
          }

          p {
            font-size: 12px;
            color: #c8c8c8;
            margin-top: 14px;
          }
        }

        .logoBox {
          width: 120px;
          height: 80px;
          background: #ffffff;
          border: 1px dashed #c8c8c8;
          text-align: center;
          position: relative;

          input {
            width: 120px;
            height: 80px;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 10;
            opacity: 0;
            cursor: pointer;
          }

          .imgBox {
            width: 120px;
            height: 80px;
            padding: 0;
            object-fit: cover;
          }

          img {
            display: block;
            margin: 0 auto;
            width: 32px;
            height: 32px;
            padding-top: 15px;
          }

          p {
            font-size: 12px;
            color: #c8c8c8;
            margin-top: 10px;
          }
        }

        .buyType {
          display: flex;

          span {
            width: 100px;
            line-height: 42px;
            padding: 0;
            text-align: left;
          }

          div {
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
          }

          .icon {
            width: 12px;
            height: 12px;
            display: block;
            margin-right: 8px;

            &.no {
              background: #FFFFFF;
              border: 1px solid #f4f4f4;
            }

            &.yes {
              background: url("../assets/img/xunzhong.png");
              background-repeat: no-repeat;
              background-size: 12px 12px;
            }
          }
        }
      }

      .btn {
        width: 360px;
        height: 42px;
        margin-top: 30px;

        button {
          display: block;
          width: 360px;
          height: 42px;
          background: #3d7eff;
          cursor: pointer;
          color: #ffffff;
          margin-left: 220px;
        }
      }
    }
  }
}
input{
  flex: 1;
  height: 100%;
}
</style>
